import { User } from '../interfaces';

export const identifyUser = (user: User) => {
  try {
    const heap = window.heap;

    heap.identify(user.id);

    heap.addUserProperties({
      firstName: user.name,
      lastName: user.surname,
      email: user.email,
      appVersion: process.env.npm_package_version,
    });
  } catch (error) {
    console.error('heap:', error);
  }
};

export const initializeHeap = (heapProjectId: string) => {
  if (window['heap']?.loaded) return;

  const script = document.createElement('script');
  script.dataset.label = 'heap-analytics';
  script.type = 'text/javascript';

  script.innerHTML = `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.dataset.label='heap-analytics';r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};heap.load("${heapProjectId}");`;

  document.body.append(script);

  return window['heap']?.loaded;
};

export const removeHeap = () => {
  window['heap'].loaded = false;

  const scripts = document.querySelectorAll('[data-label="heap-analytics"]');
  for (const script of scripts) {
    script.remove();
  }
};
